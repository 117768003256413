import {
  MARKETPLACE_CONTEXT_CHANGE_ERROR,
  MARKETPLACE_CONTEXT_CHANGE_REQUEST,
  MARKETPLACE_CONTEXT_CHANGE_SUCCESS,
  RESET_CONTEXT,
  SELLER_CONTEXT_CHANGE_REQUEST,
  SELLER_CONTEXT_CHANGE_SUCCESS,
  SELLER_CONTEXT_CHANGE_ERROR,
  RESET_SELLER_CONTEXT
} from '../Constants/ActionTypes'
import takeError from 'src/Errors/takeError'
import contextErrors from 'src/Errors/contextErrors'
import { getMarketplaceDetails } from './marketplace'
import { getSeller } from './seller'

export const changeSellerContext = (sellerId) => {
  const request = () => ({ type: SELLER_CONTEXT_CHANGE_REQUEST })
  const success = (seller) => ({ type: SELLER_CONTEXT_CHANGE_SUCCESS, seller })
  const failure = (error) => ({ type: SELLER_CONTEXT_CHANGE_ERROR, error })

  return async (dispatch) => {
    dispatch(request())

    try {
      const seller = await getSeller(sellerId)
      if (seller) {
        dispatch(success(seller))
      } else {
        dispatch(failure(takeError({}, contextErrors)))
      }
    } catch (error) {
      dispatch(failure(takeError(error, contextErrors)))
    }
  }
}

export const changeMarketplaceContext = ({ id: targetMarketplaceId, name, ein }) => async (dispatch, getState) => {
  const request = () => ({ type: MARKETPLACE_CONTEXT_CHANGE_REQUEST })
  const success = (marketplace = null) => ({ type: MARKETPLACE_CONTEXT_CHANGE_SUCCESS, marketplace })
  const failure = error => ({ type: MARKETPLACE_CONTEXT_CHANGE_ERROR, error })

  dispatch(request())

  if (!targetMarketplaceId) return dispatch(success())

  const {
    authentication: {
      token
    }
  } = getState()

  try {
    dispatch(success({
      id: targetMarketplaceId,
      token,
      name,
      ein
    }))

    dispatch(getMarketplaceDetails())
  } catch (error) {
    dispatch(failure(takeError(error, contextErrors)))
  }
}

export const resetSellerContext = () => dispatch => dispatch({ type: RESET_SELLER_CONTEXT })

export const resetContext = () => dispatch => dispatch({ type: RESET_CONTEXT })
