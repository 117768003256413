/* eslint-disable no-unused-vars */
import qs from 'qs'
import { cloneDeep } from 'lodash'
import {
  EXPORT_LIST_ERROR,
  EXPORT_LIST_REQUEST,
  EXPORT_LIST_SUCCESS,
  CREATE_EXPORT_ERROR,
  CREATE_EXPORT_REQUEST,
  CREATE_EXPORT_SUCCESS,
  RESET_CREATE_EXPORT,
  PROGRESS_EXPORT_SUCCESS,
  RESET_WATCH_FILES,
  CONTROL_EXPORT_NOTIFICATION,
  REDO_EXPORT_REQUEST,
  REDO_EXPORT_SUCCESS,
  REDO_EXPORT_FAILURE,
  DELETE_EXPORT_REQUEST,
  DELETE_EXPORT_SUCCESS,
  DELETE_EXPORT_FAILURE,
  RESET_ALL_FILES_REQUEST,
  RESET_ALL_FILES_SUCCESS,
  DOWNLOAD_EXPORT_REQUEST,
  DOWNLOAD_EXPORT_SUCCESS,
  DOWNLOAD_EXPORT_ERROR,
  DOWNLOAD_ALL_FILES_REQUEST,
  DOWNLOAD_ALL_FILES_SUCCESS
} from '../Constants/ActionTypes'
import uriRequests from 'src/Configs/uriRequests'
import fileRequestsExportsAPI from 'src/Configs/fileRequestsExportsAPI'
import { getAllColumns } from 'src/Constants/ExportColumnsOptions'
import takeError from '../Errors/takeError'
import moment from 'moment'
import exportsErrors from '../Errors/exportsErrors'

export const createExport = (type, formatFile, filters, columns = null) => {
  const request = () => ({ type: CREATE_EXPORT_REQUEST })
  const success = (data) => ({ type: CREATE_EXPORT_SUCCESS, data })
  const failure = (error) => ({ type: CREATE_EXPORT_ERROR, error })

  return (dispatch, getState) => {
    const {
      user: { id: userId },
      context: { seller }
    } = getState()

    if (!columns) {
      columns = getAllColumns(type)
    } else {
      columns = Array.from(columns)
    }

    const params = {
      columns,
      extension: formatFile,
      segment_type: filters.segment_type
    }

    // Deep copy of filters object
    let filtersToApply = cloneDeep(filters)

    if (filtersToApply?.['blocked_amount_range[gte]'] === 1) {
      filtersToApply.date_range = {
        gte: moment().add(1, 'day').startOf('day').toDate(),
        lte: moment().add(7, 'days').startOf('day').toDate()
      }
    }

    if (filtersToApply?.entry_mode) {
      filtersToApply.entry_mode = filtersToApply.entry_mode.replace(/\|/g, ',')
    }

    if (seller?.id) {
      filtersToApply.seller_id = seller.id
    }
    if (filtersToApply.date_range) {
      filtersToApply.date_range.lte = moment
        .utc(filtersToApply.date_range.lte)
        .format('YYYY-MM-DDTHH:mm:ss')
      filtersToApply.date_range.gte = moment
        .utc(filtersToApply.date_range.gte)
        .format('YYYY-MM-DDTHH:mm:ss')
    }

    if (type === 'transactions') {
      if (!filtersToApply.payment_type) {
        filtersToApply.payment_type = [
          'credit',
          'debit',
          'boleto',
          'voucher',
          'wallet',
          'nupay',
          'bolepix'
        ]
      }
    }

    if (type === 'entries') {
      if (filtersToApply.type) {
        filtersToApply.entry_type = filtersToApply.type
        delete filtersToApply.type
      }
    }

    if (type === 'transfers') {
      if (filtersToApply.status) {
        let statusPayment = filtersToApply.status.split(',')
        if (
          !statusPayment.includes('pending_bank') &&
          statusPayment.includes('succeeded')
        ) {
          filtersToApply.confirmed = 1
        } else if (
          statusPayment.includes('pending_bank') &&
          statusPayment.includes('succeeded')
        ) {
          statusPayment = statusPayment.filter(
            (status) => status !== 'pending_bank'
          )
        } else if (
          statusPayment.includes('pending_bank') &&
          !statusPayment.includes('succeeded')
        ) {
          statusPayment = statusPayment.filter(
            (status) => status !== 'pending_bank'
          )
          statusPayment.push('succeeded')
          filtersToApply.confirmed = 0
        }
        filtersToApply.status_payment = statusPayment
        delete filtersToApply.status
      }

      if (filtersToApply.type) {
        filtersToApply.transfer_type = filtersToApply.type.replace(/\|/g, ',')
        delete filtersToApply.type
      } else {
        filtersToApply.transfer_type = [
          'transfer',
          'payout_automatic',
          'cash_out_automatic',
          'payout_manual',
          'cash_out',
          'transfer_and_payout'
        ]
      }
    }

    delete filtersToApply.accessorName
    const queryString = qs.stringify(filtersToApply, {
      encode: false,
      arrayFormat: 'comma'
    })

    const uri =
      type === 'commissions' || type === 'commissions-v2'
        ? uriRequests.exportListMarkup
            .replace('{type}', type)
            .replace('{queryString}', queryString)
        : uriRequests.exportList
            .replace('{userId}', userId)
            .replace('{type}', type)
            .replace('{queryString}', queryString)

    dispatch(request())

    return fileRequestsExportsAPI
      .post(uri, { ...params })
      .then((response) => {
        const fileData = {
          filename: response.data.filename,
          type: type,
          extension: formatFile
        }
        dispatch(checkProgressFile(response.data.filename, type, formatFile))
        dispatch(success(fileData))
      })
      .catch((error) => {
        dispatch(failure(takeError(error, exportsErrors)))
      })
  }
}

export const getExportsList = (type, filter, isLoginCall = false) => {
  const request = () => ({ type: EXPORT_LIST_REQUEST })
  const success = (
    exportsList,
    progressList,
    errorList,
    totalPages,
    totalItems
  ) => ({
    type: EXPORT_LIST_SUCCESS,
    exportsList,
    progressList,
    errorList,
    totalPages,
    totalItems
  })
  const failure = (error) => ({ type: EXPORT_LIST_ERROR, error })

  return (dispatch, getState) => {
    const {
      user: { id: userId }
    } = getState()

    const uri = uriRequests.getExportedList
      .replace('{userId}', userId)
      .replace('{type}', type)
    const params = {
      sort: 'time-descending',
      ...filter
    }

    dispatch(request())

    return fileRequestsExportsAPI
      .get(uri, {
        limit: 50,
        offset: 0,
        params
      })
      .then((response) => {
        const files = response.data.files
        const listsFiles = files.reduce(
          (acc, file) => {
            if (file.status === 2) {
              acc.exportsList.push(file)
              return acc
            }
            if (file.status === -1) {
              acc.errorList.push(file)
              return acc
            } else {
              acc.progressList.push(file)
              return acc
            }
          },
          { exportsList: [], progressList: [], errorList: [] }
        )

        const numberOfPages =
          response.data.query_count && response.data.limit
            ? Math.ceil(response.data.query_count / response.data.limit)
            : 1
        const totalItems = response.data.query_count || 1
        dispatch(
          success(
            listsFiles.exportsList,
            listsFiles.progressList,
            listsFiles.errorList,
            numberOfPages,
            totalItems
          )
        )
        if (isLoginCall) {
          listsFiles.progressList.map((file) => {
            setTimeout(
              () =>
                dispatch(
                  checkProgressFile(file.filename, file.type, file.extension)
                ),
              1000
            )
          })
        }
      })
      .catch((error) => dispatch(failure(takeError(error, exportsErrors))))
  }
}

export const checkProgressFile = (filename, type, extension) => {
  const success = (file) => ({ type: PROGRESS_EXPORT_SUCCESS, file })

  return (dispatch, getState) => {
    const {
      user: { id: userId }
    } = getState()

    const uri =
      type === 'commissions' || type === 'commissions-v2'
        ? uriRequests.getProgressExportMarkup
            .replace('{type}', type)
            .replace('{filename}', filename)
        : uriRequests.getProgressExport
            .replace('{userId}', userId)
            .replace('{type}', type)
            .replace('{filename}', filename)

    return fileRequestsExportsAPI.get(uri).then((response) => {
      dispatch(
        success({
          filename,
          type,
          extension,
          status: response.data.status,
          size: response.data.size,
          progress: response.data.progress,
          total: response.data.total,
          percentage:
            (response.data.progress / response.data.total) * 100 ||
            response.data.total,
          url: response?.data?.url
        })
      )
      if (
        Number(response.data.status) === 0 ||
        Number(response.data.status) === 1
      ) {
        setTimeout(
          () => dispatch(checkProgressFile(filename, type, extension)),
          10000
        )
      }
    })
  }
}

export const downloadFile = (filename, type) => {
  const request = () => ({ type: DOWNLOAD_EXPORT_REQUEST })
  const success = (filename) => ({ type: DOWNLOAD_EXPORT_SUCCESS, filename })
  const failure = (error) => ({ type: DOWNLOAD_EXPORT_ERROR, error })

  return (dispatch, getState) => {
    const {
      user: { id: userId }
    } = getState()

    const uri =
      type === 'commissions'
        ? uriRequests.fileExportMarkup
            .replace('{type}', type)
            .replace('{filename}', filename)
        : uriRequests.fileExport
            .replace('{userId}', userId)
            .replace('{type}', type)
            .replace('{filename}', filename)

    dispatch(request())

    return fileRequestsExportsAPI
      .get(uri, {
        responseType: 'arraybuffer',
        headers: {
          Accept: 'binary/octet-stream'
        }
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        link.remove()
        dispatch(success(filename))
        dispatch(getExportsList('all'))
      })
      .catch((error) => dispatch(failure(takeError(error, exportsErrors))))
  }
}

export const downloadAllFiles = () => {
  const request = () => ({ type: DOWNLOAD_ALL_FILES_REQUEST })
  const success = () => ({ type: DOWNLOAD_ALL_FILES_SUCCESS })

  return (dispatch, getState) => {
    const {
      exports: { exportsList }
    } = getState()

    dispatch(request())

    const getAllFiles = new Promise((resolve) => {
      const result = exportsList.map((file) =>
        dispatch(downloadFile(file.filename, file.type)).then(
          (response) => response
        )
      )
      resolve(result)
    })

    return getAllFiles.then(() => {
      dispatch(success())
      dispatch(getExportsList('all'))
    })
  }
}

export const redoFile = (filename, type, extension) => {
  const request = (filename) => ({ type: REDO_EXPORT_REQUEST, filename })
  const success = (data) => ({ type: REDO_EXPORT_SUCCESS, data })
  const failure = (error) => ({ type: REDO_EXPORT_FAILURE, error })

  return (dispatch, getState) => {
    const {
      user: { id: userId }
    } = getState()

    dispatch(request(filename))

    const fileData = {
      filename,
      type,
      extension
    }

    const uri =
      type === 'commissions'
        ? uriRequests.getRedoExportMarkup
            .replace('{type}', type)
            .replace('{filename}', filename)
        : uriRequests.getRedoExport
            .replace('{userId}', userId)
            .replace('{type}', type)
            .replace('{filename}', filename)

    return fileRequestsExportsAPI
      .put(uri)
      .then((response) => {
        dispatch(success(fileData))
        dispatch(checkProgressFile(filename, type, extension))
      })
      .catch((error) => dispatch(failure(takeError(error, exportsErrors))))
  }
}

export const deleteFile = (filename, type, isMarkup) => {
  const request = (filename) => ({ type: DELETE_EXPORT_REQUEST, filename })
  const success = (filename) => ({ type: DELETE_EXPORT_SUCCESS, filename })
  const failure = (error) => ({ type: DELETE_EXPORT_FAILURE, error })

  return (dispatch, getState) => {
    const {
      user: { id: userId }
    } = getState()

    let uri = uriRequests.fileExport
      .replace('{userId}', userId)
      .replace('{type}', type)
      .replace('{filename}', filename)

    if (isMarkup) {
      uri = uriRequests.fileExportMarkup
        .replace('{type}', type)
        .replace('{filename}', filename)
    }

    dispatch(request(filename))

    return fileRequestsExportsAPI
      .delete(uri)
      .then(() => {
        dispatch(success(filename))
      })
      .catch((error) => dispatch(failure(takeError(error, exportsErrors))))
  }
}

export const resetAllFiles = () => {
  const request = () => ({ type: RESET_ALL_FILES_REQUEST })
  const success = () => ({ type: RESET_ALL_FILES_SUCCESS })

  return (dispatch, getState) => {
    const {
      exports: { exportsList, errorList }
    } = getState()

    dispatch(request())

    const files = [...errorList, ...exportsList]

    return Promise.all(
      files.map((file) =>
        dispatch(
          deleteFile(file.filename, file.type, file.type === 'commissions')
        ).then((response) => response)
      )
    ).then(() => {
      dispatch(success())
      dispatch(getExportsList('all'))
    })
  }
}

export const controlShowReportModal = (value) => (dispatch) =>
  dispatch({ type: CONTROL_EXPORT_NOTIFICATION, value })

export const resetWatchFiles = () => (dispatch) =>
  dispatch({ type: RESET_WATCH_FILES })

export const resetCreateExport = () => (dispatch) =>
  dispatch({ type: RESET_CREATE_EXPORT })

export const createAnticipationExport = (anticipationId, type) => {
  const request = () => ({ type: CREATE_EXPORT_REQUEST })
  const success = (data) => ({ type: CREATE_EXPORT_SUCCESS, data })
  const failure = (error) => ({ type: CREATE_EXPORT_ERROR, error })

  return (dispatch, getState) => {
    const {
      user: { id: userId }
    } = getState()

    dispatch(request())

    const uri = uriRequests.getAnticipationsReceipt
      .replace('{anticipationId}', anticipationId)
      .replace('{userId}', userId)

    return fileRequestsExportsAPI
      .post(uri, {
        receipt_type: type
      })
      .then((response) => {
        const fileData = {
          filename: response.data.filename,
          type: 'anticipations',
          extension: 'xlsx'
        }
        dispatch(
          checkProgressFile(response.data.filename, 'anticipations', 'xlsx')
        )
        dispatch(success(fileData))
      })
      .catch((error) => {
        dispatch(failure(takeError(error, exportsErrors)))
      })
  }
}
