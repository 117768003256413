import {
  GET_FUTURE_TRANSFERS_LIST_ERROR,
  GET_FUTURE_TRANSFERS_LIST_REQUEST,
  GET_FUTURE_TRANSFERS_LIST_SUCCESS,
  NEXT_FUTURE_TRANSFER_ERROR,
  NEXT_FUTURE_TRANSFER_REQUEST,
  NEXT_FUTURE_TRANSFER_SUCCESS,
  RESET_FUTURE_TRANSFERS,
  TOTAL_FUTURE_TRANSFERS_ERROR,
  TOTAL_FUTURE_TRANSFERS_REQUEST,
  TOTAL_FUTURE_TRANSFERS_SUCCESS
} from '../Constants/ActionTypes'
import { keys, reduce } from 'lodash'

import futureTransfersErrors from '../Errors/futureTransfersErrors'
import moment from 'moment'
import requestApi from '../Configs/request'
import takeError from '../Errors/takeError'
import uriRequests from '../Configs/uriRequests'

export const getTotalAndNextFutureTransfers = () => {
  const request = () => ([
    { type: TOTAL_FUTURE_TRANSFERS_REQUEST },
    { type: NEXT_FUTURE_TRANSFER_REQUEST }
  ])
  const success = (totalAmount, nextFutureTransfer) => ([
    { type: TOTAL_FUTURE_TRANSFERS_SUCCESS, totalAmount },
    { type: NEXT_FUTURE_TRANSFER_SUCCESS, nextFutureTransfer }
  ])
  const failure = (error) => ([
    { type: TOTAL_FUTURE_TRANSFERS_ERROR, error },
    { type: NEXT_FUTURE_TRANSFER_ERROR, error }
  ])

  const uri = uriRequests.getFutureTransfers
  const requestGetFutureTransfers = requestApi.get(uri, {
    apiVersion: '2',
    params: {
      'group_by_day': true,
      limit: 1000,
      'expected_on_range[gte]': moment().format('X'),
      'expected_on_range[lte]': moment().add(10, 'years').format('X')
    }
  })

  return (dispatch) => {
    Promise.all(request().map(action => {
      dispatch(action)
    }))

    requestGetFutureTransfers
      .then((response) => {
        const { data } = response
        const { items } = data
        const totalAmount = items && keys(items).length > 0 && reduce(items, (result, futureTransfer) => {
          if (futureTransfer && futureTransfer.amount) {
            result += (parseFloat(futureTransfer.amount) / 100)
          }
          return result
        }, 0)

        const orderedFutureTransfersDates = keys(items).sort()
        const nextDate = orderedFutureTransfersDates && orderedFutureTransfersDates.length > 0 && orderedFutureTransfersDates[0]
        if (!nextDate) {
          return Promise.all(success(totalAmount, null).map(action => {
            dispatch(action)
          }))
        }

        const nextFutureTransfer = nextDate && items[nextDate]
        return Promise.all(success(totalAmount, { ...nextFutureTransfer, date: nextDate }).map(action => {
          dispatch(action)
        }))
      })
      .catch(error => Promise.all(failure(error).map(action => {
        dispatch(action)
      })))
  }
}

export const getFutureTransfers = (params) => {
  const request = () => ({ type: GET_FUTURE_TRANSFERS_LIST_REQUEST })
  const success = (data, totalPages, limit, total) => ({ type: GET_FUTURE_TRANSFERS_LIST_SUCCESS, data, totalPages, limit, total })
  const failure = (error) => ({ type: GET_FUTURE_TRANSFERS_LIST_ERROR, error })

  let url = uriRequests.getFutureTransfers

  let applyFilters = { ...params }
  if (applyFilters.page) {
    delete applyFilters.page
  }

  if (applyFilters.date_range &&
      applyFilters.date_range.gte &&
      applyFilters.date_range.lte) {
    applyFilters.expected_on_range = {
      gte: moment(applyFilters.date_range.gte).subtract(3, 'hours').format('X'),
      lte: moment(applyFilters.date_range.lte).subtract(3, 'hours').format('X')
    }
  } else {
    applyFilters.expected_on_range = {
      gte: moment().startOf('day').subtract(3, 'hours').format('X'),
      lte: moment().endOf('day').subtract(3, 'hours').format('X')
    }
  }

  delete applyFilters.date_range
  delete applyFilters.accessor_Name

  const requestGetFutureTransfers = requestApi.get(url, {
    apiVersion: '2',
    params: {
      offset: 0,
      limit: 100,
      sort: 'time-ascending',
      ...applyFilters
    }
  })

  return (dispatch) => {
    dispatch(request())
    requestGetFutureTransfers
      .then((response) => {
        const { data } = response
        const { items, total, limit } = data
        const totalPages = Math.ceil(total / limit) || 1
        dispatch(success(items, totalPages, limit, total))
      })
      .catch((error) => dispatch(failure(takeError(error, futureTransfersErrors))))
  }
}

export const resetFutureTransfers = () => (dispatch) => dispatch({ type: RESET_FUTURE_TRANSFERS })
