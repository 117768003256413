import uriRequests from 'src/Configs/uriRequests'
import queryRequestsAPI from 'src/Configs/queryRequestsAPI'
import fileRequestsExportsAPI from 'src/Configs/fileRequestsExportsAPI'
import moment from 'moment'
import { capitalize } from 'lodash'
import takeError from 'src/Errors/takeError'
import transactionErrors from 'src/Errors/transactionErrors'
import {
  GET_COMMISSIONS_ERROR,
  GET_COMMISSIONS_REQUEST,
  GET_COMMISSIONS_SUCCESS,
  GET_LAST_COMMISSION_ERROR,
  GET_LAST_COMMISSION_REQUEST,
  GET_LAST_COMMISSION_SUCCESS
} from 'src/Constants/ActionTypes'

export const getLastCommission = () => {
  const request = () => ({ type: GET_LAST_COMMISSION_REQUEST })
  const success = (lastCommission) => ({
    type: GET_LAST_COMMISSION_SUCCESS,
    lastCommission
  })
  const failure = (error) => ({ type: GET_LAST_COMMISSION_ERROR, error })

  const commissionsFilter = {
    payment_type: 'commission',
    sort: 'created_at:desc',
    date_range: {
      gte: moment().subtract(1, 'year').toDate(),
      lte: moment().add(1, 'day').toDate()
    }
  }

  return async (dispatch, getState) => {
    const {
      marketplace: {
        details: {
          customer: { id: sellerOwnerId }
        }
      }
    } = getState()

    const uri = uriRequests.getSellerTransactions.replace(
      '{sellerId}',
      sellerOwnerId
    )

    dispatch(request())

    try {
      const response = await queryRequestsAPI.get(uri, {
        params: commissionsFilter,
        bypassSellerContext: true
      })

      const {
        data: { items }
      } = response

      if (items.length > 0) {
        const lastCommissionDate = moment(items[0].created_at)
        const commissionsOfLastMonth = items.filter((item) =>
          moment(item.created_at).isSame(lastCommissionDate, 'month')
        )

        const amount = commissionsOfLastMonth.reduce(
          (acc, current) => acc + parseFloat(current.amount),
          0
        )

        dispatch(
          success({
            date: capitalize(lastCommissionDate.format('MMM/YYYY')),
            amount
          })
        )
      } else {
        dispatch(success(null))
      }
    } catch (responseError) {
      const { response } = responseError
      const error = takeError(responseError, transactionErrors)

      if (response.status === 404) {
        return dispatch(success(null))
      }

      if (response.status === 504 || response.status === 500) {
        return dispatch(failure(error))
      }

      dispatch(failure(error))
    }
  }
}

export const getCommissions = (filters = {}) => {
  const request = () => ({ type: GET_COMMISSIONS_REQUEST })
  const success = (commissions, hasMore, page) => ({
    type: GET_COMMISSIONS_SUCCESS,
    commissions,
    hasMore,
    page
  })
  const failure = (error) => ({ type: GET_COMMISSIONS_ERROR, error })

  return async (dispatch, getState) => {
    dispatch(request())

    const {
      marketplace: {
        details: {
          customer: { id: sellerOwnerId }
        }
      }
    } = getState()

    try {
      const SOURCE_FIELDS = 'id,created_at,status,amount'

      const commissionsFilter = {
        ...filters,
        fields: SOURCE_FIELDS,
        payment_type: 'commission',
        sort: 'created_at:desc'
      }

      moment.locale('pt-br')
      const lte = moment().endOf('day')
      let gte = moment().month('JAN').year('2020').startOf('month')
      const diffMonths = lte.diff(gte, 'months', true)

      if (diffMonths > 12) {
        gte = lte.subtract(1, 'year')
      }
      commissionsFilter.date_range = {}
      commissionsFilter.date_range.gte = gte.format(
        'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'
      )
      const transactionsUri = uriRequests.getSellerTransactions.replace(
        '{sellerId}',
        sellerOwnerId
      )
      const exportsCommissionsUri = uriRequests.getExportedListMarkup.replace(
        '{type}',
        'commissions'
      )

      const exportsCommissionsV2Uri = uriRequests.getExportedListMarkup.replace(
        '{type}',
        'commissions-v2'
      )

      const queryResponse = await queryRequestsAPI.get(transactionsUri, {
        params: commissionsFilter,
        bypassSellerContext: true
      })

      const {
        data: { items, has_more: hasMore, page }
      } = queryResponse

      const {
        data: { files: commissionsFiles }
      } = await fileRequestsExportsAPI.get(exportsCommissionsUri, {
        params: {
          show_downloaded: true,
          sort: 'time-descending',
          limit: 1000
        }
      })

      const {
        data: { files: commissionsV2Files }
      } = await fileRequestsExportsAPI.get(exportsCommissionsV2Uri, {
        params: {
          show_downloaded: true,
          sort: 'time-descending',
          limit: 1000
        }
      })

      let commissions = []
      const files = [...commissionsFiles, ...commissionsV2Files]

      items.forEach((item) => {
        const { created_at: createdAt, amount, id } = item
        let newCommission = {
          commissionDate: new Date(createdAt),
          amount,
          id,
          status: null,
          filename: null
        }
        let commission = commissions.find((commission) =>
          moment(commission['date']).isSame(new Date(createdAt), 'month')
        )

        const exportedFile = files.find(
          (file) => file.filters.commission_id === id
        )

        if (exportedFile) {
          const { filename, status, url } = exportedFile
          newCommission = {
            ...newCommission,
            filename,
            status,
            url
          }
        }

        if (commission) {
          commission['commissions'].push(newCommission)
          commission['commissions'].sort((a, b) => {
            if (a.commissionDate < b.commissionDate) {
              return 1
            }
            if (a.commissionDate > b.commissionDate) {
              return -1
            }
            return 0
          })
        } else {
          commission = {
            date: new Date(createdAt),
            commissions: [newCommission]
          }
          commissions.push(commission)
        }
      })

      dispatch(success(commissions, hasMore, page))
    } catch (responseError) {
      const { response } = responseError
      const error = takeError(responseError, transactionErrors)

      if (response.status === 404) {
        return dispatch(success(null))
      }

      if (response.status === 504 || response.status === 500) {
        return dispatch(failure(error))
      }

      dispatch(failure(error))
    }
  }
}
