import uriRequests from '../Configs/uriRequests'
import dashBFFRequestApi from '../Configs/dashBFFRequest'
import takeError from 'src/Errors/takeError'
import { isEmpty } from 'lodash'

export const getFeatureFlags = async () => {
  try {
    const response = await dashBFFRequestApi.get(uriRequests.getFeatureFlags)
    if (isEmpty(response.data)) {
      return {
        'payoutEnabled': true,
        'balancesEnabled': true,
        'salesEnabled': true,
        'receivableEnabled': true,
        'creditCardSalesEnabled': true,
        'updatedAt': Date.now(),
        'transfersEnabled': true,
        'plansFeesEnabled': true,
        'posEnabled': true,
        'chargebackEnabled': true
      }
    }
    return response.data
  } catch (error) {
    return takeError(error)
  }
}

export const createFeatureFlags = async (payload) => {
  const requestData = {
    'receivableEnabled': payload.receivableEnabled,
    'chargebackEnabled': payload.chargebackEnabled,
    'posEnabled': payload.posEnabled,
    'balancesEnabled': payload.balancesEnabled,
    'payoutEnabled': payload.payoutEnabled,
    'salesEnabled': payload.salesEnabled,
    'creditCardSalesEnabled': payload.creditCardSalesEnabled,
    'transfersEnabled': payload.transfersEnabled,
    'plansFeesEnabled': payload.plansFeesEnabled
  }

  try {
    const response = await dashBFFRequestApi.put(uriRequests.createFeatureFlags, requestData)
    return response.data
  } catch (error) {
    return takeError(error)
  }
}
