/* eslint-disable no-unused-vars */
import requestApi from '../Configs/request'
import uriRequests from '../Configs/uriRequests'

export const createBuyer = async (buyer) => {
  const urlRequest = uriRequests.createBuyer
  return requestApi
    .post(urlRequest, {
      ...buyer,
      default_receipt_delivery_method: 'email'
    })
    .then((response) => {
      if (response.data) {
        return response.data
      }
    })
    .catch((e) => {
      return null
    })
}

export const updateBuyer = async (buyer) => {
  const urlRequest = uriRequests.updateBuyer.replace('{buyerId}', buyer.id)
  return requestApi
    .put(urlRequest, {
      ...buyer,
      default_receipt_delivery_method: 'email'
    })
    .then((response) => {
      if (response.data) {
        return response.data
      }
    })
    .catch((e) => {
      return null
    })
}

export const getBuyersByDocument = async (document) => {
  const urlRequest = `${uriRequests.searchBuyer}`

  return requestApi
    .get(urlRequest, { params: { ...document } })
    .then((response) => {
      if (response.data) {
        return response.data
      }
    })
    .catch(() => {
      return null
    })
}
