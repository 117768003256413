import whiteLabelCustomRequestAPI from '../Configs/whiteLabelCustomRequestAPI'
import uriRequests from '../Configs/uriRequests'
import dashBFFRequestApi from '../Configs/dashBFFRequest'
import createToast from 'src/Utils/createToast'
import takeError from 'src/Errors/takeError'

import {
  GET_CUSTOM_REQUEST,
  GET_CUSTOM_SUCCESS,
  GET_CUSTOM_ERROR,
  UPDATE_CUSTOM_COLOR_REQUEST,
  UPDATE_CUSTOM_COLOR_SUCCESS,
  UPDATE_CUSTOM_COLOR_ERROR,
  UPDATE_CUSTOM_COLORED_LOGO_REQUEST,
  UPDATE_CUSTOM_COLORED_LOGO_SUCCESS,
  UPDATE_CUSTOM_COLORED_LOGO_ERROR,
  UPDATE_CUSTOM_MONOCHROME_LOGO_REQUEST,
  UPDATE_CUSTOM_MONOCHROME_LOGO_SUCCESS,
  UPDATE_CUSTOM_MONOCHROME_LOGO_ERROR,
  UPDATE_CUSTOM_SLUG_REQUEST,
  UPDATE_CUSTOM_SLUG_SUCCESS,
  UPDATE_CUSTOM_SLUG_ERROR,
  UPDATE_SUPPORT_DATA_REQUEST,
  UPDATE_SUPPORT_DATA_SUCCESS,
  UPDATE_SUPPORT_DATA_ERROR,
  UPDATE_TEMPORARY_COLOR,
  RESET_TEMPORARY_COLOR,
  UPDATE_TEMPORARY_COLORED_LOGO,
  RESET_TEMPORARY_COLORED_LOGO,
  UPDATE_TEMPORARY_MONOCHROME_LOGO,
  RESET_TEMPORARY_MONOCHROME_LOGO,
  RESET_CUSTOM_ERRORS,
  GET_SLUG_REQUEST,
  GET_SLUG_SUCCESS,
  GET_SLUG_ERROR
} from '../Constants/ActionTypes'
import customSettingsErrors from 'src/Errors/customSettingsErrors'
import { logout } from 'src/Actions/authentication'
import { setUserStyles } from 'src/Utils/Utilities'

// eslint-disable-next-line no-unused-vars
export const getCustomByMarketplace = () => {
  const request = () => ({ type: GET_CUSTOM_REQUEST })
  const success = ({
    color,
    coloredLogo,
    monochromeLogo,
    supportPhone,
    supportEmail
  }) => ({
    type: GET_CUSTOM_SUCCESS,
    color,
    coloredLogo,
    monochromeLogo,
    supportPhone,
    supportEmail
  })
  const failure = (error) => ({ type: GET_CUSTOM_ERROR, error })

  const urlRequest = uriRequests.getCustom

  return async (dispatch) => {
    dispatch(request())

    try {
      const {
        data: { items }
      } = await whiteLabelCustomRequestAPI.get(urlRequest)

      const coloredLogoUrl = items.files?.logo_colored?.url
      const monochromeLogoUrl = items.files?.logo_monochrome?.url
      const defaultColor = items.settings?.default_color
      const supportData = items.settings?.support_data

      const custom = {
        color: defaultColor || '#999',
        coloredLogo: coloredLogoUrl,
        monochromeLogo: monochromeLogoUrl,
        supportPhone: supportData && supportData.phone_number,
        supportEmail: supportData && supportData.email
      }

      try {
        if (!coloredLogoUrl) {
          const coloredRes = await fetch(coloredLogoUrl)
          const coloredBlob = await coloredRes.blob()
          custom.coloredLogo = window.URL.createObjectURL(coloredBlob)
        }

        if (!monochromeLogoUrl) {
          const monochromeRes = await fetch(monochromeLogoUrl)
          const monochromeBlob = await monochromeRes.blob()
          custom.monochromeLogo = window.URL.createObjectURL(monochromeBlob)
        }

        setUserStyles(custom.temporaryColor || custom.color)
        dispatch(success(custom))
      } catch (e) {
        dispatch(success(custom))
      }
    } catch (error) {
      console.log(error)
      dispatch(failure(takeError(error, customSettingsErrors)))
    }
  }
}

export const updateMarketplaceCustomColor = (marketplaceId, color) => {
  const request = () => ({ type: UPDATE_CUSTOM_COLOR_REQUEST })
  const success = (color) => ({ type: UPDATE_CUSTOM_COLOR_SUCCESS, color })
  const failure = (error) => ({ type: UPDATE_CUSTOM_COLOR_ERROR, error })

  const urlRequest = uriRequests.updateCustomColor

  return async (dispatch) => {
    dispatch(request())

    try {
      const {
        data: {
          items: { default_color: defaultColor }
        }
      } = await whiteLabelCustomRequestAPI.patch(urlRequest, {
        default_color: color
      })

      dispatch(success(defaultColor))
      createToast(
        'success',
        'Sucesso! Cor foi <strong>atualizada</strong>.<br />Faça login novamente no seu POS para ver as mudanças de layout.'
      )
    } catch (error) {
      const e = takeError(error, customSettingsErrors)
      if (e.status === 500) {
        createToast(
          'error',
          'Não conseguimos <strong>alterar</strong> sua cor. Tente novamente.',
          marketplaceId,
          e.status
        )
      } else {
        createToast('error', e.message, marketplaceId, e.status)
      }
      dispatch(failure(e))
    }
  }
}

export const updateTemporaryColor = (color) => (dispatch) =>
  dispatch({ type: UPDATE_TEMPORARY_COLOR, color })

export const resetTemporaryColor = () => (dispatch) =>
  dispatch({ type: RESET_TEMPORARY_COLOR })

export const resetCustomErrors = (errorType) => (dispatch) =>
  dispatch({ type: RESET_CUSTOM_ERRORS, errorType })

export const updateLogo = (marketplaceId, logo, logoLabel) => {
  const isColoredLogo = logoLabel === 'logo-colored.png'
  const requestType = isColoredLogo
    ? UPDATE_CUSTOM_COLORED_LOGO_REQUEST
    : UPDATE_CUSTOM_MONOCHROME_LOGO_REQUEST
  const successType = isColoredLogo
    ? UPDATE_CUSTOM_COLORED_LOGO_SUCCESS
    : UPDATE_CUSTOM_MONOCHROME_LOGO_SUCCESS
  const failureType = isColoredLogo
    ? UPDATE_CUSTOM_COLORED_LOGO_ERROR
    : UPDATE_CUSTOM_MONOCHROME_LOGO_ERROR

  const request = () => ({ type: requestType })
  const success = () => ({ type: successType, logoSource: logo.preview })
  const failure = (error) => ({ type: failureType, error })

  const urlRequest = uriRequests.uploadFile.replace('{name}', logoLabel)

  return async (dispatch) => {
    dispatch(request())

    try {
      await whiteLabelCustomRequestAPI.put(urlRequest, logo.file, {
        headers: {
          'content-type': logo.file.type
        }
      })

      dispatch(success())
      createToast(
        'success',
        'Sucesso! Logo foi <strong>atualizada</strong>.<br />Faça login novamente no seu POS para ver as mudanças de layout.'
      )
    } catch (error) {
      const e = takeError(error, customSettingsErrors)
      if (e.status === 500) {
        createToast(
          'error',
          'Não conseguimos <strong>alterar</strong> sua logo. Tente novamente.',
          marketplaceId,
          e.status
        )
      } else {
        createToast('error', e.message, marketplaceId, e.status)
      }
      dispatch(failure(e))
    }
  }
}

export const updateTemporaryLogo = (category, logo) => (dispatch) => {
  dispatch({
    type:
      category === 'colored'
        ? UPDATE_TEMPORARY_COLORED_LOGO
        : UPDATE_TEMPORARY_MONOCHROME_LOGO,
    logo
  })
}

export const resetTemporaryLogo = (category) => (dispatch) => {
  dispatch({
    type:
      category === 'colored'
        ? RESET_TEMPORARY_COLORED_LOGO
        : RESET_TEMPORARY_MONOCHROME_LOGO
  })
}

export const updateCustomMarketplaceSlug = (marketplaceId, data) => {
  const request = () => {
    return { type: UPDATE_CUSTOM_SLUG_REQUEST }
  }
  const success = () => {
    return { type: UPDATE_CUSTOM_SLUG_SUCCESS }
  }
  const failure = (error) => {
    return { type: UPDATE_CUSTOM_SLUG_ERROR, error }
  }

  return async (dispatch) => {
    dispatch(request())

    const urlRequest = uriRequests.updateCustomMarketplaceSlug

    return dashBFFRequestApi
      .put(urlRequest, data)
      .then(() => {
        dispatch(success())
        createToast(
          'success',
          'Sucesso! Sua URL foi <strong>modificada</strong>, você será <strong>redirecionado</strong> para a tela de login.'
        )
        setTimeout(() => dispatch(logout()), 3000)
      })
      .catch((error) => {
        const e = takeError(error, customSettingsErrors)
        if (e.status === 500) {
          createToast(
            'error',
            'Não conseguimos <strong>alterar</strong> a URL. Tente novamente.',
            marketplaceId,
            e.status
          )
        } else {
          createToast('error', e.message, marketplaceId, e.status)
        }
        dispatch(failure(e))
      })
  }
}

export const updateSupportData = (marketplaceId, data) => {
  const request = () => {
    return { type: UPDATE_SUPPORT_DATA_REQUEST }
  }
  const success = (data) => {
    return { type: UPDATE_SUPPORT_DATA_SUCCESS, data }
  }
  const failure = (error) => {
    return { type: UPDATE_SUPPORT_DATA_ERROR, error }
  }

  return async (dispatch) => {
    dispatch(request())

    const urlRequest = uriRequests.updateSupportData

    return whiteLabelCustomRequestAPI
      .patch(urlRequest, data)
      .then(
        ({
          data: {
            items: {
              support_data: { email: supportEmail, phone_number: supportPhone }
            }
          }
        }) => {
          dispatch(success({ supportEmail, supportPhone }))
          createToast(
            'success',
            'Sucesso! Seus dados de suporte agora estão <strong>atualizados</strong>.'
          )
        }
      )
      .catch((error) => {
        const e = takeError(error, customSettingsErrors)
        if (e.status === 500) {
          createToast(
            'error',
            'Não conseguimos <strong>atualizar</strong> seus dados de suporte. Tente novamente.',
            marketplaceId,
            e.status
          )
        } else {
          createToast('error', e.message, marketplaceId, e.status)
        }
        dispatch(failure(e))
      })
  }
}

export const getSlug = () => {
  const request = () => {
    return { type: GET_SLUG_REQUEST }
  }
  const success = (slug) => {
    return { type: GET_SLUG_SUCCESS, slug }
  }
  const failure = (error) => {
    return { type: GET_SLUG_ERROR, error }
  }

  const urlRequest = uriRequests.getSlugByMarketplaceId

  return (dispatch) => {
    try {
      dispatch(request())

      return dashBFFRequestApi
        .get(urlRequest)
        .then((response) => {
          const { slug } = response.data
          dispatch(success(slug))
        })
        .catch((error) => {
          dispatch(failure(error))
        })
    } catch (error) {
      dispatch(failure(error))
    }
  }
}
