import uriRequests from '../Configs/uriRequests'
import dashboardApiRequest from 'src/Configs/dashboardApiRequest'

import {
  UPDATE_FAVORITE_MARKETPLACES,
  FAVORITE_MARKETPLACES_REQUEST,
  FAVORITE_MARKETPLACES_SUCCESS,
  FAVORITE_MARKETPLACES_ERROR,
  UPDATE_FAVORITE_SELLERS,
  FAVORITE_SELLERS_REQUEST,
  FAVORITE_SELLERS_SUCCESS,
  FAVORITE_SELLERS_ERROR
} from '../Constants/ActionTypes'
import takeError from '../Errors/takeError'
import favoriteErrors from '../Errors/favoriteErrors'
import queryRequestsAPI from '../Configs/queryRequestsAPI'

export const getFavoritesSellers = () => {
  const request = () => ({ type: FAVORITE_SELLERS_REQUEST })
  const success = (data) => ({ type: FAVORITE_SELLERS_SUCCESS, data })
  const failure = (error) => ({ type: FAVORITE_SELLERS_ERROR, error })

  return async (dispatch, getState) => {
    const {
      user: {
        id: userId
      }
    } = getState()

    const uri = uriRequests.favoriteSellers
      .replace('{userId}', userId)

    dispatch(request())
    try {
      const favoriteSellersIdsResponse = await dashboardApiRequest.get(uri)

      if (
        favoriteSellersIdsResponse &&
        favoriteSellersIdsResponse.data &&
        favoriteSellersIdsResponse.data.sellers
      ) {
        let favoriteSellersResponse = await queryRequestsAPI.get(uriRequests.getSellers, {
          params: {
            seller_ids: favoriteSellersIdsResponse.data.sellers.toString()
          }
        })

        let favoriteSellers = []

        if (
          favoriteSellersResponse &&
          favoriteSellersResponse.data &&
          favoriteSellersResponse.data.items.length
        ) {
          favoriteSellers = favoriteSellersResponse.data.items.map(seller => ({
            ...seller,
            isFavorite: true
          }))
        }

        dispatch(success(favoriteSellers))
      }
    } catch (e) {
      dispatch(failure(takeError(e, favoriteErrors)))
    }
  }
}

export const setFavoriteSeller = (seller) => {
  return (dispatch, getState) => {
    const {
      user: {
        id: userId
      },
      favorites: {
        favoriteSellers
      }
    } = getState()

    const uri = uriRequests.favoriteSellerId
      .replace('{userId}', userId)
      .replace('{sellerId}', seller.id)

    try {
      const newFavorites = [...favoriteSellers, {
        ...seller,
        isFavorite: true
      }]

      dispatch(
        {
          type: UPDATE_FAVORITE_SELLERS,
          data: newFavorites
        }
      )

      dashboardApiRequest.put(uri)
    } catch (e) {
      // Do nothing
    }
  }
}

export const deleteFavoriteSeller = (seller) => {
  return (dispatch, getState) => {
    const {
      user: {
        id: userId
      },
      favorites: {
        favoriteSellers
      }
    } = getState()

    const uri = uriRequests.favoriteSellerId
      .replace('{userId}', userId)
      .replace('{sellerId}', seller.id)

    try {
      const newFavorites = favoriteSellers.filter(item => item.id !== seller.id)

      dispatch(
        {
          type: UPDATE_FAVORITE_SELLERS,
          data: newFavorites
        }
      )

      dashboardApiRequest.delete(uri)
    } catch (e) {
      // Do nothing
    }
  }
}

export const getFavoritesMarketplaces = () => {
  const request = () => ({ type: FAVORITE_MARKETPLACES_REQUEST })
  const success = (data) => ({ type: FAVORITE_MARKETPLACES_SUCCESS, data })
  const failure = (error) => ({ type: FAVORITE_MARKETPLACES_ERROR, error })

  return async (dispatch, getState) => {
    const {
      user: {
        id: userId
      }
    } = getState()

    const uri = uriRequests.favoriteMarketplaces
      .replace('{userId}', userId)

    dispatch(request())
    try {
      const { data: { children } } = await dashboardApiRequest.get(uri)
      dispatch(success(children))
    } catch (e) {
      dispatch(failure(takeError(e, favoriteErrors)))
    }
  }
}

export const setFavoriteMarketplace = (marketplace) => {
  return (dispatch, getState) => {
    const {
      user: {
        id: userId
      },
      favorites: {
        favoriteMarketplaces
      }
    } = getState()

    const uri = uriRequests.favoriteMarketplaceId
      .replace('{userId}', userId)
      .replace('{marketplaceId}', marketplace.id)

    try {
      const newFavorites = [...favoriteMarketplaces, marketplace.id]

      dispatch(
        {
          type: UPDATE_FAVORITE_MARKETPLACES,
          data: newFavorites
        }
      )

      dashboardApiRequest.put(uri)
    } catch (e) {
      // Do nothing
    }
  }
}

export const deleteFavoriteMarketplace = (marketplace) => {
  return (dispatch, getState) => {
    const {
      user: {
        id: userId
      },
      favorites: {
        favoriteMarketplaces
      }
    } = getState()

    const uri = uriRequests.favoriteMarketplaceId
      .replace('{userId}', userId)
      .replace('{marketplaceId}', marketplace.id)

    try {
      const newFavorites = favoriteMarketplaces.filter(item => item !== marketplace.id)

      dispatch(
        {
          type: UPDATE_FAVORITE_MARKETPLACES,
          data: newFavorites
        }
      )

      dashboardApiRequest.delete(uri)
    } catch (e) {
      // Do nothing
    }
  }
}
